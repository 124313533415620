import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
      <g id="H" transform="translate(19.000000, 27.000000)">
        <path
          d="M 55.029 0.537 L 60.889 0.537 L 60.889 36.523 L 55.029 36.523 L 55.029 20.313 L 41.455 20.313 L 41.455 16.309 L 55.029 16.309 L 55.029 0.537 Z M 13.819 0 L 13.819 4.883 Q 12.207 5.127 10.791 5.981 A 7.319 7.319 0 0 0 9.117 7.387 A 9.473 9.473 0 0 0 8.301 8.447 A 10.402 10.402 0 0 0 7.396 10.146 Q 6.941 11.214 6.616 12.524 A 20.504 20.504 0 0 0 6.19 14.855 Q 6.036 16.062 5.982 17.43 A 37.354 37.354 0 0 0 5.957 18.359 A 34.047 34.047 0 0 0 6.09 20.975 Q 6.203 22.209 6.409 23.279 A 17.723 17.723 0 0 0 6.616 24.219 A 16.746 16.746 0 0 0 7.239 26.205 Q 7.632 27.22 8.134 28.053 A 9.848 9.848 0 0 0 8.301 28.32 A 9.086 9.086 0 0 0 9.467 29.785 A 7.11 7.11 0 0 0 10.816 30.859 A 7.708 7.708 0 0 0 13.045 31.793 A 7.127 7.127 0 0 0 13.819 31.934 L 13.819 36.719 A 14.251 14.251 0 0 1 9.001 35.572 A 13.538 13.538 0 0 1 8.325 35.254 A 13.278 13.278 0 0 1 3.955 31.689 A 15.244 15.244 0 0 1 2.036 28.594 A 19.967 19.967 0 0 1 1.05 26.05 A 22.036 22.036 0 0 1 0.239 22.274 Q 0 20.43 0 18.359 A 30.588 30.588 0 0 1 0.223 14.571 Q 0.468 12.614 0.98 10.918 A 18.692 18.692 0 0 1 1.05 10.693 A 19.35 19.35 0 0 1 2.301 7.629 A 15.02 15.02 0 0 1 3.955 5.078 Q 5.811 2.783 8.325 1.514 Q 10.84 0.244 13.819 0 Z M 33.496 36.523 L 33.496 0.537 L 39.453 0.537 L 39.453 36.523 L 33.496 36.523 Z M 25 3.857 L 20.947 7.471 A 9.248 9.248 0 0 0 19.806 6.375 A 7.042 7.042 0 0 0 18.555 5.566 Q 17.285 4.932 15.82 4.834 L 15.82 0 A 14.448 14.448 0 0 1 21.117 1.202 A 13.337 13.337 0 0 1 25 3.857 Z M 15.82 36.768 L 15.82 31.934 Q 17.285 31.787 18.579 31.152 A 7.261 7.261 0 0 0 20.326 29.937 A 8.902 8.902 0 0 0 20.947 29.297 L 25 32.861 A 13.481 13.481 0 0 1 20.85 35.645 A 14.327 14.327 0 0 1 16.913 36.674 A 16.862 16.862 0 0 1 15.82 36.768 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="       M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
  </svg>
);

export default IconLoader;
